<template>
  <div>
    <!-- 卡片视图区域 -->
    <el-card>
      <!-- 第一行，输入框和添加按钮 -->
      <el-row>
        <!-- 添加按钮 -->
        <el-col :span="5">
          <el-button type="primary" @click="showAddVideoDialog"
            >+ 新增设备</el-button
          >
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <!-- 输入框，尾部带按钮 -->
          <el-input
            placeholder="请输入设备名称"
            v-model="queryInfo.deviceName"
            clearable
            @clear="getVideoList"
            @change="getVideoList"
          >
            <el-button slot="append" @click="getVideoList">搜索</el-button>
          </el-input>
        </el-col>
      </el-row>
      <!-- 内容主体 -->
      <el-table :data="videoList" stripe border>
        <!-- 第一列id -->
        <el-table-column label="id" aligin="center" prop="id">
        </el-table-column>
        <!-- <el-table-column label="设备编号" aligin="center" prop="deviceId">
        </el-table-column> -->
        <!-- 第二列名称 -->
        <el-table-column label="设备名称" aligin="center" prop="deviceName">
        </el-table-column>
        <el-table-column label="设备品牌" aligin="center" prop="brand">
        </el-table-column>
        <el-table-column label="设备类型" aligin="center">
          <template slot-scope="scope">
            <div v-if="scope.row.deviceType === 'ipc'">摄像头</div>
          </template>
        </el-table-column>
        <el-table-column label="设备能力" aligin="center">
          <template slot-scope="scope">
            <div
              v-for="(item, index) in scope.row.deviceCapabilities"
              :key="index"
            >
              <div v-if="item === 'human_number'">客流统计</div>
              <div v-else-if="item === 'line_alarm'">伴线入侵</div>
              <div v-else-if="item === 'item_left'">物品遗留</div>
              <div v-else>热成像</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="设备IP" width="130" aligin="center" prop="ip">
        </el-table-column>
        <el-table-column label="设备端口" aligin="center" prop="port">
        </el-table-column>
        <el-table-column label="设备国标" aligin="center" prop="gbId">
        </el-table-column>
        <el-table-column label="用户名" aligin="center" prop="userName">
        </el-table-column>
        <el-table-column label="序列号" aligin="center" prop="sn">
        </el-table-column>
        <!-- 第三列备注 -->
        <el-table-column label="备注" aligin="center" prop="remark">
        </el-table-column>
        <el-table-column label="描述" aligin="center" prop="description">
        </el-table-column>
        <!-- 第五列操作 -->
        <el-table-column label="操作" width="250" aligin="center">
          <template slot-scope="scope">
            <!-- 查看按钮 -->
            <el-button
              size="mini"
              type="warning"
              @click="goVideoControl(scope.row.id)"
              >查看</el-button
            >
            <!-- 编辑按钮 -->
            <el-button
              size="mini"
              type="primary"
              @click="showEditQuestionDialog(scope.row)"
              >修改</el-button
            >
            <!-- 删除按钮 -->
            <el-button
              size="mini"
              type="danger"
              @click="removeQuestionById(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNo"
        :page-sizes="[15, 30, 50, 100]"
        :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 新增对话框 -->
    <el-dialog
      :title="title"
      :visible.sync="addQuestionDialogVisible"
      width="800px"
      @close="clearAddInfo"
    >
      <el-form
        ref="commonQuestionFormListRef"
        :rules="commonQuestionFormListRules"
        :model="videoSurveillanceDeviceForm"
        label-width="100px"
      >
        <div class="input">
          <el-form-item label="设备名称" prop="deviceName">
            <el-input
              v-model="videoSurveillanceDeviceForm.deviceName"
              placeholder="请输入设备名称"
              clearable=""
            ></el-input>
          </el-form-item>
          <el-form-item label="设备品牌" prop="brand">
            <el-select
              v-model="videoSurveillanceDeviceForm.brand"
              placeholder="请选择设备品牌"
            >
              <el-option
                v-for="(item, index) in brandList"
                :key="index"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="input">
          <!-- 设备类型 -->
          <el-form-item label="设备类型" prop="deviceType">
            <el-select
              v-model="videoSurveillanceDeviceForm.deviceType"
              placeholder="请选择设备类型"
              disabled
            >
              <el-option
                v-for="(item, index) in deviceTypeList"
                :key="index"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 国标id -->
          <el-form-item label="设备国标id" prop="gbId">
            <el-input
              v-model="videoSurveillanceDeviceForm.gbId"
              placeholder="请输入设备国标id"
              disabled
            ></el-input>
          </el-form-item>
        </div>
        <div class="input">
          <el-form-item label="设备能力" prop="deviceCapabilities">
            <el-select
              v-model="videoSurveillanceDeviceForm.deviceCapabilities"
              placeholder="请选择设备能力"
              multiple
              @change="change"
            >
              <el-option
                v-for="(item, index) in deviceCapabilitiesList"
                :key="index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="input">
          <el-form-item label="设备IP" prop="ip">
            <el-input
              v-model="videoSurveillanceDeviceForm.ip"
              placeholder="请输入设备IP"
              clearable=""
            ></el-input>
          </el-form-item>
          <el-form-item label="设备端口" prop="port">
            <el-input
              v-model="videoSurveillanceDeviceForm.port"
              placeholder="请输入设备端口"
              clearable=""
            ></el-input>
          </el-form-item>
        </div>
        <div class="input">
          <!-- 用户名 -->
          <el-form-item label="设备用户名" prop="gbId">
            <el-input
              v-model="videoSurveillanceDeviceForm.gbId"
              placeholder="请输入设备用户名"
              disabled
            ></el-input>
          </el-form-item>
          <!-- 密码 -->
          <el-form-item label="设备密码" prop="password">
            <el-input
              v-model="videoSurveillanceDeviceForm.password"
              placeholder="请输入设备密码"
              clearable=""
              show-password
            ></el-input>
          </el-form-item>
        </div>
        <div class="input">
          <!-- 序列号 -->
          <el-form-item label="设备序列号" prop="sn">
            <el-input
              v-model="videoSurveillanceDeviceForm.sn"
              placeholder="请输入设备序列号"
              clearable=""
            ></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input
              v-model="videoSurveillanceDeviceForm.remark"
              placeholder="请输入备注信息"
              clearable=""
            ></el-input>
          </el-form-item>
        </div>
        <el-form-item label="设备描述" prop="description">
          <el-input
            v-model="videoSurveillanceDeviceForm.description"
            placeholder="请输入描述信息"
            clearable=""
            type="textarea"
            :rows="3"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addQuestionDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      queryInfo: {
        pageNo: 1,
        limit: 15,
        groupId: '',
        marketId: '',
        deviceName: ''
      },
      // 设备列表
      videoList: [],
      // 总数
      total: 0,
      // 标题
      title: '',
      // 新增还是修改
      submitType: '',
      // 修改的id
      videoId: '',
      id: [],
      // 对话框
      addQuestionDialogVisible: false,
      // 新增入参
      videoSurveillanceDeviceForm: {
        brand: '', // 品牌
        description: '',
        deviceName: '',
        deviceType: 'ipc', // 设备类型
        gbId: '', // 国标id
        groupId: undefined,
        ip: '',
        marketId: undefined,
        password: '',
        port: '',
        remark: '',
        sn: '',
        userName: '', // 设备用户名-和国标id一样
        deviceCapabilities: [] // 设备能力 human_number-客流统计line_alarm-伴线入侵-对应占到经营item_left-物品遗留-对应垃圾堆放tpc-热成像-对应温度检测
      },
      // 品牌
      brandList: ['海康', '大华'],
      // 设备能力列表
      deviceCapabilitiesList: [
        {
          value: 'human_number',
          label: '客流统计'
        },
        {
          value: 'line_alarm',
          label: '伴线入侵'
        },
        {
          value: 'item_left',
          label: '物品遗留'
        },
        {
          value: 'tpc',
          label: '热成像'
        }
      ],
      // 设备类型
      deviceTypeList: [
        {
          id: 'ipc',
          name: '摄像头'
        },
        {
          id: 'platform',
          name: '平台'
        },
        {
          id: 'ied',
          name: '智能设备'
        }
      ],
      // 校验
      commonQuestionFormListRules: {
        deviceName: [
          { required: true, message: '请输入设备名称', trigger: 'blur' }
        ],
        brand: [{ required: true, message: '请选择设备品牌', trigger: 'blur' }],
        deviceType: [
          { required: true, message: '请选择设备类型', trigger: 'blur' }
        ],
        gbId: [
          { required: true, message: '请输入设备国标id', trigger: 'blur' }
        ],
        ip: [{ required: true, message: '请输入设备IP', trigger: 'blur' }],
        port: [{ required: true, message: '请输入设备端口', trigger: 'blur' }],
        userName: [
          { required: true, message: '请输入设备用户名', trigger: 'blur' }
        ],
        // password: [
        //   { required: true, message: '请输入设备密码', trigger: 'blur' }
        // ],
        sn: [{ required: true, message: '请输入设备序列号', trigger: 'blur' }],
        deviceCapabilities: [
          { required: true, message: '请选择设备能力', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    // 接收id参数
    this.queryInfo.groupId = this.$route.query.id
    // 存到session中
    // window.sessionStorage.setItem('groupId', this.$route.query.id)
    this.videoSurveillanceDeviceForm.groupId = this.$route.query.id
    // 请求该类型下的设备列表
    this.getVideoList()
  },
  methods: {
    // 定义设备列表方法
    async getVideoList() {
      // 给marketId赋值
      this.queryInfo.marketId = window.sessionStorage.getItem('currentMarketId')
      const { data: res } = await this.$http.get(
        'video/videoSurveillanceDevice/list',
        {
          params: this.queryInfo
        }
      )
      if (res.code !== 0) {
        return this.$message.error('查询设备列表失败')
      }
      // 赋值
      this.videoList = res.data.data
      this.total = res.data.total
    },
    // limit变化，调用列表接口
    handleSizeChange(newSize) {
      this.queryInfo.limit = newSize
      this.getVideoList()
    },
    // 页码变化，调用接口
    handleCurrentChange(newPage) {
      this.queryInfo.pageNo = newPage
      this.getVideoList()
    },
    // 点击新增按钮
    showAddVideoDialog() {
      this.title = '新增设备'
      this.submitType = 'add'
      // 调用生成国标
      this.generateGbId()
    },
    // 提交新增和修改请求
    async submit() {
      this.$refs.commonQuestionFormListRef.validate(async valid => {
        // console.log(valid)
        if (!valid) return
        // 可以发起网络请求
        if (this.submitType === 'add') {
          this.videoSurveillanceDeviceForm.marketId = parseInt(
            window.sessionStorage.getItem('currentMarketId')
          )
          // 将用户名赋值
          this.videoSurveillanceDeviceForm.userName = this.videoSurveillanceDeviceForm.gbId
          const { data: res } = await this.$http.post(
            'video/videoSurveillanceDevice',
            this.videoSurveillanceDeviceForm
          )
          if (res.code !== 0) {
            return this.$message.error(res.message)
          }
          this.$message.success('添加设备成功')
          this.getVideoList()
          this.addQuestionDialogVisible = false
        } else {
          // 赋值成功后提交
          const { data: res } = await this.$http.put(
            `video/videoSurveillanceDevice/${this.videoId}`,
            this.videoSurveillanceDeviceForm
          )
          if (res.code !== 0) {
            return this.$message.error(res.message)
          }
          this.$message.success('修改设备成功')
          this.getVideoList()
          this.addQuestionDialogVisible = false
        }
      })
    },
    // 关闭对话框
    clearAddInfo() {
      // 清空设备和办法，不能清除groupId和marketId
      this.videoSurveillanceDeviceForm = {}
      this.videoSurveillanceDeviceForm.deviceType = 'ipc'
      this.videoSurveillanceDeviceForm.groupId = this.$route.query.id
      this.videoSurveillanceDeviceForm.marketId = parseInt(
        window.sessionStorage.getItem('currentMarketId')
      )
      this.videoSurveillanceDeviceForm.gbId = ''
      this.getVideoList()
    },
    // 编辑
    showEditQuestionDialog(row) {
      this.videoSurveillanceDeviceForm = row
      this.addQuestionDialogVisible = true
      this.title = '修改设备'
      this.submitType = 'edit'
      // 赋值修改的id
      this.videoId = row.id
    },
    // 删除
    async removeQuestionById(deviceId) {
      this.id = deviceId
      // 弹框提醒
      const confirmResult = await this.$confirm(
        '此操作将永久删除该设备，是否继续？',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除')
      }
      const { data: res } = await this.$http.delete(
        `video/videoSurveillanceDevice/${this.id}`
      )
      if (res.code !== 0) {
        return this.$message.error(res.message)
      }
      this.$message.success('删除设备成功')
      this.getVideoList()
    },
    // 跳转到云台控制
    goVideoControl(id) {
      this.$router.push({ path: 'video-265', query: { key: id } })
    },
    // 生成国标id
    async generateGbId() {
      const { data: res } = await this.$http.post(
        'video/videoSurveillanceDevice/generateGbId'
      )
      if (res.code !== 0) {
        return this.$message.error(res.message)
      }
      // 成功后赋值
      this.videoSurveillanceDeviceForm.gbId = res.data
      console.log(this.videoSurveillanceDeviceForm.gbId)
      this.addQuestionDialogVisible = true
    },
    change() {
      this.$forceUpdate()
    }
  }
}
</script>

<style lang="less" scoped>
.el-row {
  margin-bottom: 20px;
}
.input {
  display: flex;
  justify-content: space-between;
}
</style>
